import React from "react"
import Footer from "../footer/Footer"
import Header from "../header/Header"
import ContactMeForm from "./ContactMeForm"

export default function ContactMe() {
    return (
        <>
            <Header />
            <ContactMeForm />
            <Footer />
        </>
    )
}